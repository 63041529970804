var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-row',{staticClass:"dashboard-row-height mb-4"},[_c('b-col',[(
        _vm.inProgressCourses !== null &&
          _vm.inProgressCourses.results.length === 0 &&
          _vm.allCompletedCourses.results.length === 0
      )?_c('Alert',{staticClass:"welcome-back__msg-alert d-none d-md-block"},[_c('div',{staticClass:"align-self-center"},[_c('b',{staticClass:"welcome-back__msg-alert--title"},[_vm._v(" "+_vm._s(_vm.$t("general.hello"))+" ")]),_c('span',{staticClass:"username"},[_vm._v(_vm._s(_vm.$t("dashboard.welcome_back.user_name", { s: _vm.getAuthProfile.name }))+",")]),_c('p',{staticClass:"welcome-back__msg-alert--subtitle"},[_vm._v(" "+_vm._s(_vm.$t("dashboard.welcome_back.message"))+" ")])]),_c('div',{staticClass:"welcome-back__enroll-today"},[_c('LxpButton',{staticClass:"text-nowrap",attrs:{"variant":"accent"},on:{"click":function($event){return _vm.$emit('startLearning')}}},[_c('img',{attrs:{"src":require("@/assets/images/courseware/school-responsive-white.svg"),"alt":"school"}}),_vm._v(" "+_vm._s(_vm.$t("dashboard.welcome_back.enroll_today"))+" ")])],1)]):_vm._e(),(
        _vm.inProgressCourses !== null &&
          _vm.inProgressCourses.results.length === 0 &&
          _vm.allCompletedCourses.results.length === 0
      )?_c('Alert',{staticClass:"welcome-back__msg-alert-mobile d-block d-md-none"},[_c('div',[_c('div',{staticClass:"d-flex"},[_c('b',{staticClass:"title"},[_vm._v(" "+_vm._s(_vm.$t("dashboard.welcome_back.mobile_view.title"))+" ")])]),_c('div',{staticClass:"d-flex content"},[_vm._v(" "+_vm._s(_vm.$t("dashboard.welcome_back.mobile_view.content"))+" ")]),_c('div',{staticClass:"mt-3 d-flex justify-content-center"},[_c('LxpButton',{staticClass:"text-nowrap",attrs:{"variant":"accent"},on:{"click":function($event){return _vm.$emit('startLearning')}}},[_c('img',{attrs:{"src":require("@/assets/images/courseware/school-responsive-white.svg"),"alt":"school"}}),_vm._v(" "+_vm._s(_vm.$t("dashboard.welcome_back.enroll_today"))+" ")])],1)])]):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }