<template>
  <div class="lxp-dashboard__my-courses">
    <b-row class="h-100" v-if="isLoading">
      <b-col
        cols="12"
        sm="6"
        md="4"
        lg="3"
        class="mb-3"
        v-for="index in 4"
        :key="index"
      >
        <shimmer
          variant="inProgress-course-card"
          style="width: 100%; height: 100%;"
        />
      </b-col>
    </b-row>
    <div v-else>
      <div class="row lx-course-card__scroll" v-if="courseCount > 0">
        <!-- We are making a 4 x 4 grid and filling with the InProgressCourse flex container up to 4 -->
        <div
          class="my-3 lx-inprogress-course__wrapper"
          :class="{
            'col-12 col-sm-6 col-lg-3 col-md-4 cm__myProgrmmes':
              courses.length > 3,
            'col-12 col-lg-4 col-md-4': courses.length === 3,
            'col-12 col-lg-6 col-md-6': courses.length <= 2,
            'd-sm-none d-md-none d-lg-block': i == 3
          }"
          v-for="(course, i) in courses"
          :key="i"
          v-show="allEnrolledCourses.length > 1"
        >
          <template>
            <InprogressCourse
              :courseImage="
                course.image_url
                  ? course.image_url
                  : require('@/assets/images/illustration/course-illustration.svg')
              "
              :org="course.org"
              :courseTitle="course.title"
              :progressValue="
                getProgressValue(course.metrics.progress_percentage * 100)
              "
              :bg="`bg-1`"
              :key="`my-prg-${i}`"
              :courseId="course.id"
              :course="course"
              @unenrollData="getUnenrollData"
              @onMyCardClick="$emit('onMyCardClick', course)"
              @click="$emit('click', courses[i])"
              @orgClick="onOrgClick(courses[i])"
            />
          </template>
        </div>
      </div>
      <div v-if="courseCount === 1">
        <div class="row">
          <div
            class="col-lg-6 mt-3 mt-md-0 mt-lg-0 mb-3 col-md-6 lx-inprogress-course__wrapper"
          >
            <template>
              <InprogressCourse
                :courseImage="
                  courses[0].image_url
                    ? courses[0].image_url
                    : require('@/assets/images/illustration/course-illustration.svg')
                "
                :org="courses[0].org"
                :courseTitle="courses[0].title"
                :progressValue="
                  getProgressValue(courses[0].metrics.progress_percentage * 100)
                "
                :bg="`bg-1`"
                :key="`my-prg-1`"
                :course-id="courses[0].id"
                @unenrollData="getUnenrollData"
                @onMyCardClick="$emit('onMyCardClick', courses[0])"
                @click="$emit('click', courses[0])"
                @orgClick="onOrgClick(courses[0])"
              />
            </template>
          </div>
          <div
            class="col-lg-6 col-md-6 mb-3"
            v-if="completedCourses.length === 0"
          >
            <Alert class="lxp-dashboard__first-enroll-course h-100">
              <div class="align-self-center text-center">
                <div class="d-flex justify-content-center">
                  <b>
                    {{ $t("dashboard.alert.enrolled_first_course.title") }}
                  </b>
                </div>
                <div class="d-flex justify-content-center">
                  {{ $t("dashboard.alert.message") }}
                </div>
                <div class="d-flex justify-content-center mt-3">
                  <LxpButton
                    className="text-nowrap text-uppercase"
                    variant="outline-accent"
                    @click="$emit('startLearning')"
                  >
                    {{ $t("dashboard.alert.explore") }}
                  </LxpButton>
                </div>
              </div>
            </Alert>
          </div>
          <div
            class="col-lg-6 col-md-6 mb-3"
            v-else-if="completedCourses.length >= 1 && courses.length === 1"
          >
            <Alert class="lxp-dashboard__first-enroll-course">
              <div>
                <div class="d-flex justify-content-center lxp__alert--title">
                  <b>
                    {{ $t("dashboard.alert.never_stop_learning.title") }}
                  </b>
                </div>
                <div class="d-flex justify-content-center lxp__alert--message">
                  {{ $t("dashboard.alert.message") }}
                </div>
                <div
                  class="d-flex justify-content-center mt-3 lxp__alert--enroll"
                >
                  <Button
                    pill
                    class="text-nowrap text-uppercase"
                    variant="outline-primary"
                    @click="$emit('startLearning')"
                    >{{ $t("dashboard.alert.explore") }}</Button
                  >
                </div>
              </div>
            </Alert>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import redirectToLearningSpace from "@/core/mixins/redirectToLearningSpace";
import { LxpButton } from "didactica";
export default {
  mixins: [redirectToLearningSpace],
  components: { LxpButton },
  props: {
    isLoading: {
      type: Boolean,
      default: true
    },
    courses: {
      type: Array
    },
    completedCourses: {
      type: Array
    },
    allEnrolledCourses: {
      type: Array
    }
  },

  computed: {
    courseCount() {
      return this.courses.length > 3 ? 4 : this.courses.length;
    }
  },
  methods: {
    getProgressValue(value) {
      let number = 0;
      number = value ? value.toFixed(1) : 0;
      return parseFloat(number);
    },
    getUnenrollData(data) {
      this.$emit("unenrollModalData", data);
    },
    onOrgClick(c) {
      this.navigateToLearningSpace(c.org);
    }
  }
};
</script>

<style lang="scss" scoped>
.lxp-dashboard__my-courses {
  height: calc(100% - 40px);
  padding: 20px 0;
  .cm__myProgrmmes {
    padding: 0 12px;
  }
  .lx-inprogress-course {
    max-height: 100%;
    width: 100%;
  }

  .empty-state-img {
    margin-bottom: 30px;
  }
  .section-content {
    @include body-regular;
    padding: 0 30px;
    text-align: center;
    color: #00937e;
    margin-bottom: 30px;
  }

  .btn {
    padding-left: 30px;
    padding-right: 30px;
  }
  .lxp-dashboard__first-enroll-course {
    .lxp__alert--title {
      @include subtitle-regular;
      text-align: center;
      color: $brand-neutral-700;
    }
    .lxp__alert--message {
      @include body-regular;
      text-align: center;
    }
    .lxp__alert--enroll {
      @include body-regular;
      text-align: center;
      letter-spacing: 1.25px;
      color: $brand-primary-400;
    }
    .alert {
      min-height: 168px;
      padding-top: 5%;
      padding-bottom: 5%;
      margin-bottom: 0px;
      text-align: center;
      background-color: $brand-primary-100;
      border: none !important;
    }
    .align-items-start {
      justify-content: center !important;
      color: #434343;
    }
  }
}
@media screen and (max-width: 480px) {
  .lxp-dashboard__my-courses {
    padding: 4px 0;
    .lx-course-card__scroll {
      overflow: auto;
      flex-wrap: nowrap;
    }
  }
}
</style>
