<template>
  <b-row class="dashboard-row-height mb-4">
    <b-col>
      <Alert
        class="welcome-back__msg-alert d-none d-md-block"
        v-if="
          inProgressCourses !== null &&
            inProgressCourses.results.length === 0 &&
            allCompletedCourses.results.length === 0
        "
      >
        <div class="align-self-center">
          <b class="welcome-back__msg-alert--title">
            {{ $t("general.hello") }}
          </b>
          <span class="username"
            >{{
              $t("dashboard.welcome_back.user_name", {
                s: getAuthProfile.name
              })
            }},</span
          >

          <p class="welcome-back__msg-alert--subtitle">
            {{ $t("dashboard.welcome_back.message") }}
          </p>
        </div>
        <div class="welcome-back__enroll-today">
          <LxpButton
            @click="$emit('startLearning')"
            class="text-nowrap"
            variant="accent"
          >
            <img
              src="@/assets/images/courseware/school-responsive-white.svg"
              alt="school"
            />
            {{ $t("dashboard.welcome_back.enroll_today") }}
          </LxpButton>
        </div>
      </Alert>
      <!-- mobile section start-->
      <Alert
        class="welcome-back__msg-alert-mobile d-block d-md-none"
        v-if="
          inProgressCourses !== null &&
            inProgressCourses.results.length === 0 &&
            allCompletedCourses.results.length === 0
        "
      >
        <div>
          <div class="d-flex">
            <b class="title">
              {{ $t("dashboard.welcome_back.mobile_view.title") }}
            </b>
          </div>
          <div class="d-flex content">
            {{ $t("dashboard.welcome_back.mobile_view.content") }}
          </div>
          <div class="mt-3 d-flex justify-content-center">
            <LxpButton
              @click="$emit('startLearning')"
              class="text-nowrap"
              variant="accent"
            >
              <img
                src="@/assets/images/courseware/school-responsive-white.svg"
                alt="school"
              />
              {{ $t("dashboard.welcome_back.enroll_today") }}
            </LxpButton>
          </div>
        </div>
      </Alert>
      <!-- mobile section end-->
    </b-col>
  </b-row>
</template>
<script>
import { mapGetters } from "vuex";
import { LxpButton } from "didactica";

export default {
  emits: ["startLearning"],
  components: { LxpButton },
  computed: {
    ...mapGetters([
      "showCompleteProfile",
      "getAuthProfile",
      "allCompletedCourses",
      "inProgressCourses"
    ])
  }
};
</script>
